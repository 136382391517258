import { ref, watch, computed } from '@vue/composition-api'
import { getVehicles } from '@api'
import { useUtils } from '@core/libs/i18n'
import { isObjEmpty } from '@core/utils'
import usePermissions from '@core/utils/usePermissions'
import useFilters from '@core/utils/useFilters'

export default function useVehicleList() {
  const listTable = ref([])
  const { t } = useUtils()
  const { hasActionPermission, hasPermission } = usePermissions()
  const { updateFilter, getFilterByModule } = useFilters()

  const tableColumns = computed(() => [
    { text: t('users.id_number').toUpperCase(), value: 'id_number', show: true },
    { text: t('payment_methods.brand').toUpperCase(), value: 'brand', show: true },
    { text: t('projects.model').toUpperCase(), value: 'model', show: true },
    { text: t('projects.color').toUpperCase(), value: 'color', show: true },
    { text: t('projects.capacity').toUpperCase(), value: 'capacity', show: true },
    { text: t('transactions.note').toUpperCase(), value: 'note', show: true },
    { text: t('status.status').toUpperCase(), value: 'status', show: true },
    {
      text: t('tooltip.actions').toUpperCase(),
      value: 'actions',
      align: 'center',
      sortable: false,
      show: hasActionPermission(31, 33, 34),
    },
  ])
  const computedTableColumns = computed(() => tableColumns.value.filter(e => e.show))

  const searchQuery = ref('')
  const totalListTable = ref(0)
  const loading = ref(false)
  const options = ref({
    sortBy: ['id_number'],
    sortDesc: [true],
  })
  const filters = ref(null)
  const flag = ref(true)
  const hasOptionsFilter = ref(false)
  const timerSearch = ref(null)
  const totalLocal = ref([])

  const filterData = item =>
    /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
    item.id_number.toLowerCase().includes(searchQuery.value) || item.brand.toLowerCase().includes(searchQuery.value) || item.model.toLowerCase().includes(searchQuery.value) || item.color.toLowerCase().includes(searchQuery.value) || item.capacity.toString().toLowerCase().includes(searchQuery.value) || (item.note && item.note.toLowerCase().includes(searchQuery.value))

  // fetch data
  const fetchData = async () => {
    const response = await getVehicles()

    const filterOption = {}
    filters.value = null
    if (searchQuery.value) filterOption.search = searchQuery.value
    filters.value = filterOption

    const filteredData = response.data.filter(filterData)

    listTable.value = filteredData
    totalListTable.value = filteredData.length
    totalLocal.value = null
    loading.value = false
  }

  const setOptions = value => {
    flag.value = false
    if (value) {
      hasOptionsFilter.value = true
      options.value = value
    } else {
      hasOptionsFilter.value = false
    }
  }

  const setFilters = value => {
    flag.value = false
    if (value && !isObjEmpty(value)) {
      if (value.search) searchQuery.value = value.search
    }
  }

  const getDataBySearch = async () => {
    clearTimeout(timerSearch.value)
    timerSearch.value = setTimeout(async () => {
      await fetchData()
    }, 1000)
  }

  watch([searchQuery], async () => {
    loading.value = true
    flag.value = false
    if (!hasOptionsFilter.value) options.value.page = 1
    updateFilter('views-vehicle-list', 'search', searchQuery.value)
    await getDataBySearch()
  })

  watch([options], async () => {
    if (flag.value) {
      loading.value = true
      await fetchData()
    }
  })

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveStatusVariant = status => {
    if (status === 'pending') return 'warning'
    if (status === 'active' || status === 'A') return 'success'
    if (status === 'inactive' || status === 'I') return 'error'

    return 'primary'
  }

  const resolveStatusText = status => {
    if (status === 'A') return 'Activo'
    if (status === 'I') return 'Inactivo'

    return 'Administrator'
  }

  return {
    listTable,
    tableColumns,
    computedTableColumns,
    searchQuery,
    totalListTable,
    loading,
    options,
    filters,
    totalLocal,
    fetchData,
    resolveStatusVariant,
    resolveStatusText,
    hasPermission,
    setFilters,
    setOptions,
    updateFilter,
    getFilterByModule,
  }
}
